<template>
  <v-app class="app-container">
    <v-container class="text-center">
      <v-row
        align="center"
        class="my-8"
      >
        <v-col cols="12">
          <h1 class="white--text font-weight-light bigger-font">
            La tua registrazione e' in fase di elaborazione...
          </h1>
          <h1 class="font-weight-light white--text">
            Caro Professore, attendi che i Sorintiani approvino la tua
            richiesta!
          </h1>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mb-8"
      >
        <v-col
          cols="12"
          md="8"
        >
          <h2 class="font-weight-light white--text mb-5">
            Nel frattempo, guarda il trailer di Hackersgen!
          </h2>

          <iframe
            id="video-emb"
            src="https://player.vimeo.com/video/588745940?autoplay=1"
            title="Hackergen trailer"
            allowfullscreen
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          color="secondary_accent white--text"
          to="/"
        >
          Torna alla home
        </v-btn>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "WaitingApproved",
};
</script>

<style scoped>
.bigger-font {
  font-size: 3.2rem;
}
#video-emb {
  max-width: 560px;
  max-height: 315px;
  height: 50vh;
  width: 100%;
  border: none;
}

#a {
  color: #f37021;
  text-decoration: none;
}

#a:visited {
  color: #f37021;
  text-decoration: none;
}

#a:hover {
  color: white;
  text-decoration: underline;
}

#a:active {
  text-decoration: underline;
}
</style>
